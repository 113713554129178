/*MapSearch*/
.mapSearchContainer {
  border-radius: 30px;
  width: 100%;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  line-height: 45px;
  min-width: 350px;
  flex-basis: auto;
  align-items: center;
}

.mapSearchContainer #mapIcon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: inherit;
  color: rgb(0, 0, 0, 0.7);
}

#mapSearchTextField {
  flex: 1 1 auto;
  min-width: 230px;
  border-style: none;
  margin-left: 5px;
  font-size: 1rem;
}
.mapSearchContainer input:focus {
  outline: none !important;
}

.mapSearchContainer #mapSearchBtn {
  border-radius: 30px;
  width: 75px;
  height: 100%;
  color: white;
  font-weight: bolder;
  font-size: 1rem;
  background-color: #a5cf46;
  border: 2px solid #ffffff;
}
#mapSearchBtn:hover {
  background-color: green;
}

@media only screen and (max-width: 600px) {
  .mapSearchContainer #mapSearchBtn {
    font-size: 1rem;
  }
  #mapSearchTextField {
    font-size: 1rem;
  }
}
