.quote {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - 20px);
  padding: 0px 50px 20px 50px;
  row-gap: 20px;
  overflow: scroll;
}

.quote .addressDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quote .addressDate .address {
  display: block;
  line-height: 2px;
}
.quote .addressDate .date {
  display: flex;
  column-gap: 5px;
  line-height: 2px;
}

.servicesMaterialCosts {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.servicesMaterialCosts .services {
  background-color: gray;
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 700;
  font-size: large;
  margin: 0px;
}
.servicesMaterialCosts p {
  margin: 0px;
  padding: 5px 20px;
}
.servicesMaterialCosts .servicesList p {
  margin: 0px;
  padding: 10px 30px;
}

.servicesMaterialCosts .servicesList {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  margin: 0px;
}

.quote .btnsTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quote .btnsTotal .btns {
  display: flex;
  color: white;
  text-align: center;
}
.quote .btnsTotal .btns .approveBtn {
  background-color: #a5cf46;
  cursor: pointer;
  width: 220px;
  height: 70px;
}
.quote .btnsTotal .btns .declineBtn {
  background-color: maroon;
  cursor: pointer;
  width: 220px;
  height: 70px;
}
.quote .btnsTotal .btns {
  display: flex;
  gap: 30px;
}

.quote .btnsTotal .total {
  padding-right: 30px;
  line-height: 4px;
  column-gap: 30px;
  display: flex;
  font-size: large;
  text-align: right;
}
.quote .btnsTotal .total .totalLable {
  font-weight: 700;
}

.quote .notes {
  background-color: blue;
}
.btnsTotal .btnSection {
  display: flex;
  flex-direction: column;
}

.assumptionsTerms {
  white-space: pre-line;
  font-size: 1rem;
  padding: 0px 30px;
}

@media only screen and (max-width: 600px) {
  .quote {
    padding: 5px;
    height: calc(100vh - 5px);
    row-gap: 10px;
  }
  .quote .btnsTotal {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .btnsTotal .btnSection {
    width: 100%;
  }
  .assumptionsTerms {
    padding: 0px 15px;
    font-size: 0.8rem;
  }
}
