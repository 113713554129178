/*BusinessCredential*/
.BusinessCredentialContainer {
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.2rem;
}
.BusinessCredentialContainer li {
  color: white;
}
@media only screen and (max-width: 600px) {
  .BusinessCredentialContainer {
    font-size: 0.9rem;
  }
}
