.edit {
  display: flex;
  flex-direction: column;
}
.edit .titleMain {
  text-align: center;
  color: white;
  margin-bottom: 20;
}
.edit .button h1 {
  font-size: 30px;
  color: white;
}
.section {
  display: flex;
  justify-content: space-evenly;
  color: white;
}
.edit .section h1 {
  font-size: 1rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vw;
  width: 100vw;
}
.button {
  background-color: #a5cf46;
  padding: 0px 5vw;
  color: white;
  font-size: large;
}

@media only screen and (max-width: 600px) {
  .title h1 {
    white-space: normal;
  }
  .section {
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-start;
    height: 80vh;
    align-items: center;
  }
  .section .sectionA {
    padding: 0px 20px;
  }
  .section .sectionB {
    padding: 0px 20px;
  }
  .edit .titleMain {
    font-size: large;
  }
}
