/*Electrical*/
.electricalInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  color: white;
  font-weight: bolder;
}

.electricalInfo .infoContainer {
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px;
  height: 80%;
}

.electricalInfo .sample {
  grid-row: 1;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  aspect-ratio: 2/3;
}

.sampleImgBorder {
  width: 90%;
  height: 90%;
  border: 5px solid #a5cf46;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.electricalInfo .sampleImage {
  width: 80%;
  height: 80%;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
}

.electricalInfo .infoContainer .location {
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2%;
  width: 2%;
}

.electricalInfo .MultiselectTitle {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 15px;
}

.MultiselectTitle .mySelect {
  width: 300px;
  background-color: white;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 2vw;
}

.MultiselectTitle .mySelect .mySelectContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;

  padding: 0px 1rem;
}

.MultiselectTitle .mySelect p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  white-space: nowrap;
  overflow: hidden;
  width: 95%;
}

.electricalInfo .buttonContainer {
  display: flex;
  align-items: center;
  margin-top: 5vw;
  width: 15vw;
  cursor: pointer;
}
.electricalInfo .button {
  background-color: #a5cf46;
  padding: 0px 60px;
  color: white;
}

.electricalInfo .button h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 30px;
}

.electricalInfo .title h1 {
  font-size: 1rem;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .electricalInfo {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .electricalInfo .sample {
    display: none;
  }
  .electricalInfo .buttonContainer {
    width: inherit;
  }
  .electricalInfo .MultiselectTitle .mySelect {
    width: 200px;
  }

  .MultiselectTitle {
    align-items: center;
    text-align: center;
  }
  .electricalInfo .infoContainer {
    align-items: center;
  }
}
