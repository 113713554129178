body {
  background: url(../images/backgroundPG1.jpg) no-repeat;
  margin: 0;
  padding: 0;
  height: 100vh;
  background-size: cover;
  font-family: sans-serif;
}

.A h1 {
  font-size: 2rem;
  text-align: center;
  color: white;
  letter-spacing: -0.1rem;
  font-weight: bold;
  margin-bottom: 50px;
}

.box {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  position: absolute;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}
