.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.purchaseCharger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
}

.purchaseCharger .title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 20vh;
  color: white;
}

.purchaseCharger .buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  margin-top: 5vw;
  cursor: pointer;
}
.purchaseCharger .button {
  background-color: #a5cf46;
  padding: 0px 60px;
  color: white;
  font-size: large;
}

.purchaseCharger .button h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 30px;
}

.purchaseCharger .title h1 {
  font-size: 1.5rem;
}

.mySelect {
  width: 450px;
  background-color: white;
  border-radius: 4vw;
}

.aaa {
  background-color: white;
  border-radius: 5vw;
}

@media only screen and (max-width: 600px) {
  .purchaseCharger .buttonContainer {
    width: inherit;
  }
  .purchaseCharger .title h1 {
    white-space: normal;
    text-align: center;
    font-size: 1.1rem;
  }
  .mySelect {
    width: 80vw;
  }
}
