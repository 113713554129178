.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.contact {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
}

.contact .title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 20vh;
  color: white;
}
.contact .title h1 {
  font-size: xx-large;
}

.contact .contentContainer {
  display: flex;
  justify-content: space-around;
  width: 90vw;
  height: 50vh;
}
.contactInfo {
  width: 100px;
  height: 100%;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.contact .bCredential {
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact .buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 20vh;
}
.contact .button {
  background-color: #a5cf46;
  padding: 0px 20px;
  color: white;
  font-size: large;
}

.firstLastNameContainer {
  display: flex;
  justify-content: space-evenly;
  gap: 2vw;
  width: 100%;
}

.fNameText {
  background-color: white;
  border-radius: 5vw;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.lNameText {
  background-color: white;
  border-radius: 5vw;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.emailContainer {
  width: 100%;
}
.email {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 5vw;
  padding: 10px;
}

.phoneNumberContianer {
  width: calc(50% - 1vw);
  margin-right: calc(50% + 1vw);
}
.phoneNumber {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 5vw;
  padding: 10px;
}

.additionalInfoContainer {
  width: 100%;
  height: 120px;
}
.additionalInfo {
  display: flex;
  background-color: white;
  border-radius: 1vw;
  padding: 10px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .contact {
    align-items: center;
  }
  .contact .contentContainer .bCredential {
    display: none;
  }
  .contact .contentContainer .contactInfo {
    width: 100%;
  }
  .contact .title {
    text-align: center;
  }
  .contact .title h {
    font-size: 1rem;
  }
  .contact .buttonContainer {
    width: 80%;
  }
  .contact .buttonContainer h1 {
    font-size: 1.2rem;
  }
}
