@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #a5cf46;
  }
  to {
    box-shadow: 0 0 10px 10px #a5cf46;
  }
}

/*options_1*/

.options_1 {
  display: inline-grid;
  grid-template-rows: 1fr 8fr;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  gap: 10px;
  color: white;
  font-weight: bolder;
}

.options_1 .header {
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_1 .option_A {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options_1 .item {
  height: 90%;
  aspect-ratio: 3 / 4;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.options_1 .item:hover {
  animation: glow 1s infinite alternate;
}

.options_1 .item h1 {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .options_1 {
    grid-template-rows: 1fr 4fr;
  }
  .options_1 h1 {
    font-size: 1.2rem;
  }
  .options_1 .header {
    margin-bottom: -10vh;
  }
  .options_1 .option_A .item {
    width: 80vw;
    aspect-ratio: 4 / 4;
    height: auto;
  }
}

/*options_1_YesNo*/

.options_1_YesNo {
  display: inline-grid;
  grid-template-rows: 1fr 3fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 10px;
  color: white;
  font-weight: bolder;
}

.options_1_YesNo .header {
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_1_YesNo .optionImgContainer {
  grid-row: 2;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.options_1_YesNo .optionImg {
  height: 90%;
  aspect-ratio: 4 / 3;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.options_1_YesNo .option_Yes {
  grid-row: 3;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
}
.options_1_YesNo .option_No {
  grid-row: 3;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}

.options_1_YesNo .item {
  width: 45%;
  aspect-ratio: 4 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.options_1_YesNo .item h1 {
  margin: 0;
}
.options_1_YesNo .item:hover {
  animation: glow 1s infinite alternate;
}

.options_1_YesNo .option_No .item {
  background-color: maroon;
}
.options_1_YesNo .option_Yes .item {
  background-color: #a5cf46;
}

@media only screen and (max-width: 600px) {
  .options_1_YesNo .option_No .item {
    width: 160px;
  }
  .options_1_YesNo .option_Yes .item {
    width: 160px;
  }
  .options_1_YesNo .optionImg {
    width: 80vw;
    height: 55vw;
  }
}

/*options_2*/

.options_2 {
  display: inline-grid;
  grid-template-rows: 1fr 8fr;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 10px;
  color: white;
  font-weight: bolder;
}

.options_2 .header {
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_2 .option_A {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  display: flex;

  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 30px;
}
.options_2 .option_B {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
}

.options_2 .item {
  height: 85%;
  aspect-ratio: 3 / 4;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.options_2 .item:hover {
  animation: glow 1s infinite alternate;
}
.options_2 .item h1 {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .options_2 {
    grid-template-rows: 1fr 4fr 4fr;
    gap: 0px;
  }
  .options_2 h1 {
    font-size: 1.2rem;
  }
  .options_2 .option_A {
    grid-row: 2;
    grid-column: 1 / -1;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }
  .options_2 .option_B {
    grid-row: 3;
    grid-column: 1 / -1;
    argin-right: 0px;
    margin: 0px;
    justify-content: center;
  }
  .options_2 .item {
    width: 60vw;
    height: 70vw;
  }
}

/*options_3*/

.options_3 {
  display: inline-grid;
  grid-template-rows: 1fr 4fr 4fr;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 20px;
  color: white;
  font-weight: bolder;
}

.options_3 .header {
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_3 .option_A {
  grid-row: 2 / -1;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.options_3 .option_B {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.options_3 .option_C {
  grid-row: 3;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.options_3 .item {
  background-color: violet;
  height: 90%;
  aspect-ratio: 3 / 6;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.options_3 .item:hover {
  animation: glow 1s infinite alternate;
}
.options_3 .item h1 {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

.options_3 .landscape {
  height: 85%;
  aspect-ratio: 16 / 15;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
}
.options_3 .item:hover {
  animation: glow 1s infinite alternate;
}
.options_3 .option_C .landscape {
  margin-top: -20px;
}

@media only screen and (max-width: 600px) {
  .options_3 {
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 40px;
  }
  .options_3 .option_A {
    align-items: center;
  }
  .options_3 .option_B {
    align-items: flex-end;
  }
  .options_3 .option_C {
    align-items: flex-start;
  }
  .options_3 .item {
    width: 40vw;
    height: calc(80vw + 40px);
    justify-content: center;
    margin-top: -20px;
  }
  .options_3 .landscape {
    width: 40vw;
    aspect-ratio: 3 / 3;
    height: auto;
    justify-content: center;
  }
  .options_3 .header h1 {
    margin-bottom: -8vh;
  }
}

/*options_3_Landscape*/

.options_3_Landscape {
  display: inline-grid;
  grid-template-rows: 1fr 4fr;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 40px;
  color: white;
  font-weight: bolder;
}

.options_3_Landscape .header {
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_3_Landscape .option_A {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.options_3_Landscape .option_B {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.options_3_Landscape .option_C {
  grid-row: 2;
  grid-column: 3;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.options_3_Landscape .item {
  background-color: violet;
  width: 85%;
  aspect-ratio: 3 / 4;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.options_3_Landscape .item:hover {
  animation: glow 1s infinite alternate;
}
.options_3_Landscape .item h1 {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

/*options_4*/

.options_4 {
  display: inline-grid;
  grid-template-rows: 1fr 4fr 4fr;
  grid-template-columns: 1fr 1fr;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  column-gap: 25px;
  color: white;
  font-weight: bolder;
}

.options_4 .header {
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.options_4 .option_A {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.options_4 .option_B {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.options_4 .option_C {
  grid-row: 3;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.options_4 .option_D {
  grid-row: 3;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.options_4 .item {
  background-color: violet;
  height: 85%;
  aspect-ratio: 4 / 3;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.options_4 .item:hover {
  animation: glow 1s infinite alternate;
}

.options_4 .item h1 {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .options_4 .item h1 {
    font-size: 1.2rem;
  }
  .options_4 {
    grid-template-rows: 1fr 2fr 2fr 2fr 2fr;
    align-items: baseline;
    gap: 0px;
  }
  .options_4 .option_A {
    grid-row: 2;
    grid-column: 1 / -1;
    margin-right: 0px;
    margin-left: 0px;
    justify-content: center;
  }
  .options_4 .option_B {
    grid-row: 3;
    grid-column: 1 / -1;
    argin-right: 0px;
    margin-left: 0px;
    justify-content: center;
  }
  .options_4 .option_C {
    grid-row: 4;
    grid-column: 1 / -1;
    argin-right: 0px;
    margin-left: 0px;
    justify-content: center;
  }
  .options_4 .option_D {
    grid-row: 5;
    grid-column: 1 / -1;
    argin-right: 0px;
    margin-left: 0px;
    justify-content: center;
  }
  .options_4 .item {
    width: 70vw;
    aspect-ratio: 4 / 2;
  }
}

/*Electrical*/
.electrical {
  display: inline-grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  gap: 30px;
  color: white;
  font-weight: bolder;
  padding: 5%;
}

.electrical .infoContainer {
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: teal;
  flex-direction: column;
  padding: 30px;
}

.electrical .sample {
  grid-row: 1;
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
}

.electrical .sampleImage {
  background-color: violet;
  width: 90%;
  height: 90%;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
}

.electrical .infoContainer .location {
  background-color: violet;
  border: 5px solid #a5cf46;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}
